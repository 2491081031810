
// Navs
// --------------------------------------------------


// Base class
// --------------------------------------------------
.foot {
  > h4 {
        color: @navbar-color-light;
        font-size: ceil((@font-size-base * 0.85)); 
  }
  > ul {
  > li {
    position: relative;
    display: block;

    > a {
      font-size: ceil((@font-size-base * 0.75)); 
      position: relative;
      display: block;
//      padding: @nav-link-padding;
      color: #eee;
      &:hover,
      &:focus {
        text-decoration: none;
        background-color: transparent;
      }
    }

    // Disabled state sets text to gray and nukes hover/tab effects
    &.disabled > a {
      color: @nav-disabled-link-color;

      &:hover,
      &:focus {
        color: @gray-light;
        text-decoration: none;
        background-color: transparent;
        cursor: @cursor-disabled;
      }
    }
  }

  }
}
.site-footer {
  margin-bottom: 0;
  padding-left: 0; // Override default ul/ol
  list-style: none;
  &:extend(.clearfix all);
  background-color: #000;
  color: #eee;
  padding-top: 10px;
  padding-bottom: 10px;




}
